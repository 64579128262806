<template>
  <div class="container" style="position: relative; height:60vh; width:90vw">
      <Bar
      :options="chartOptions"
      :data="chartData"
      
      />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)

export default {
  name: 'BarChart',
  props: {
    chartDataSet: {
      type: Object
    },
    variantsArray: {
      type: Array,
    }
  },
  components: { Bar },
  data() {
    return {
      chart_arrays: [],
      label_array: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 3,
        scales:{
            y: {
              alignToPixels: true,
              display: true,
              grid: {
                display: false,
                offset: true
              },
              ticks: {
                min: 0, 
                beginAtZero: true,
                callback: function(value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
            }
        },
        indexAxis: 'x',
        plugins: {
          colors: {
            enabled: true
          },
          legend:{
            display: true,
            align: 'center',
            position: 'bottom',
            labels: {
              boxWidth: 15,
              boxHeight: 5,
              padding: 5,
              textAlign: 'left',
              font: {
                size: 14,
              },
            },
          }
        }
      }
    }
  },
  computed: {
    chartData() { return {
      labels: this.chartDataSet.labels,
      datasets: this.chartDataSet.datasets,
      borderColor: '#36A2EB',
      backgroundColor: ''
      };
    },
  },
  watch: {
    chartDataSet: {
      handler(newValue){
        this.chart_arrays = [...newValue.datasets];
        this.label_array= [newValue.labels];
      },
        deep: true
    }
  }
}
</script>