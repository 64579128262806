<template>
  <div id="App">
    <template v-for="(item,k) in chartDataSet"         v-bind:key="k">
    <BarChart
      :chartDataSet="item"
    />
    </template>
  </div>
</template>

<script>
import BarChart from './components/BarChart.vue';

  export default {
    name: 'App',
    components: { BarChart },
    data(){
      return{
        chartDataSet: [],
        url: '',
        machine_name: '',
        keyField: '',
        valueField: '',
      }
    },
    methods: {
      getPosts() {
//        let urlStr = process.env.VUE_APP_API_URL;
//        let urlObject = new URL(urlStr);
        let urlObject=new URL(document.location);
        this.machine_name = urlObject.searchParams.get('machine_name');

        let valueStr = urlObject.searchParams.get('value_field');

        let valueFieldArr = valueStr.split(',');


        let keyStr = urlObject.searchParams.get('key_field');

        const keyFieldArr = keyStr.split(',');

        const labelsArr =  urlObject.searchParams.get('labels').split('|');

        keyFieldArr.forEach((key_item,key_index)=>{
          fetch(process.env.VUE_APP_PREFIX + '/list?machine_name=' + this.machine_name + '&value_field=' + valueFieldArr[key_index] + '&key_field=' + keyFieldArr[key_index])
            .then(response => response.json())
            .then ((data) => {
              let voteArr = [];
              let variantsArr = [];
              let datasetsArray = [];
              let pollLabelArray = [];
              let uuidKeys = (Object.keys(data.poll_variants));
              uuidKeys.forEach((key) =>{
                voteArr.push(data.votes[key]);
                variantsArr.push(data.poll_variants[key]);
              });
              for(let i=0; i<variantsArr.length; i++){
                datasetsArray.push(
                  {
                    label: variantsArr[i],
                    data: [voteArr[i]]
                  }
                )
              };
              this.chartDataSet.push({
                labels: [labelsArr[key_index]],
                datasets: datasetsArray,
              });
            })
        })
      }
    },
    mounted(){
      window.Telegram.WebApp.MainButton.hide();
      this.getPosts();
    }
  }
</script>
